import React, {useState} from 'react';
import './App.css';
import {Alert, Box, Button, Container, Grid, Paper, Snackbar, Typography} from "@mui/material";
import {CopyAll} from "@mui/icons-material";
import {useCopyToClipboard} from "usehooks-ts";

function App() {
  const [, copy] = useCopyToClipboard()
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  return (
    <Box>
      <Container style={{maxWidth: 1000}}>
        <Paper elevation={1} sx={{mt: 2}}>
          <Grid container sx={{p: 4}}>
            <Grid item md={10} xs={12}>
              <Typography variant="h4" fontWeight="bold" sx={{mb: 2}}>
                LED-Flutlichtanlage SV 66 Oberbergkirchen
              </Typography>
              <Typography variant="subtitle1" align="justify">
                LED-Flutlichtanlage für das Adam-Holzner-Stadion in Oberbergkirchen.
              </Typography>
              <Typography variant="subtitle1" sx={{mb: 2}} align="justify">
                Aufbau neuer energieeffizienter und umweltfreundlicher Beleuchtung.
              </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <img src="svo-logo.png" height="100" width="100" alt="spieler"/>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
          mb: 2,
          mr: 4,
          ml: 4,
        }}>
          <img src="rasen.jpeg" alt="rasen" style={{width: '100%', borderRadius: 5}}/>
        </Box>
        <Paper elevation={1} sx={{mt: 2, p: 4}}>
          <Typography variant="h5" fontWeight="bold" sx={{mb: 1}}>
            Über das Projekt
          </Typography>
          <Typography align="justify" sx={{mb: 2}}>
            Die Fußballabteilung des SV 66 Oberbergkirchen plant nun schon seit längerem, auf dem Hauptplatz eine
            Flutlichtanlage zu errichten. Aufgrund der Vielzahl an Mannschaften im Verein ist es dringend notwendig auch
            Abendspiele auf dem Hauptplatz austragen zu können und somit den Trainingsplatz zu schonen.
          </Typography>

          <Typography align="justify">
            Zudem gibt es aktuell eine Förderung vom BLSV von 45 %.
          </Typography>
        </Paper>
        <Paper elevation={1} sx={{mt: 2, p: 4}}>
          <Typography variant="h5" fontWeight="bold" sx={{mb: 1}}>
            WARUM brauchen wir EUCH?
          </Typography>
          <Typography align="justify" sx={{mb: 2}}>
            Die Investitionssumme für dieses Projekt beläuft sich auf 75.000 €.
            Für den Verein ist dies leider aktuell ohne fremde Unterstützung nicht möglich.
            Neben dem 45 % - Zuschuss vom BLSV beteiligt sich die Gemeinde mit 20.000 €.
            Somit bleiben ca. 20.000 € Restsumme, welche der Verein selber aufbringen muss.
          </Typography>

          <Typography align="justify">
            Und deshalb bitten wir um Eure Hilfe und Unterstützung, um diesen Traum verwirklichen zu können!
          </Typography>
        </Paper>
        <Paper elevation={1} sx={{mt: 2, p: 4}}>
          <Typography variant="h5" fontWeight="bold" sx={{mb: 1}}>
            Zielgruppe
          </Typography>
          <Typography align="justify">
            Von dieser neuen Flutlichtanlage auf dem Hauptplatz würden sämtliche Mannschaften des SVO profitieren. Neben
            den beiden Herren-Mannschaften auch die beiden Damen-Mannschaften, und vor allem auch die A- und B-Jugend,
            damit diese ihre Heimspiele nicht mehr am Sonntag um 11:00 Uhr austragen müssen.
          </Typography>
        </Paper>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
          mb: 2,
          mr: 4,
          ml: 4,
        }}>
          <img src="spieler.jpeg" alt="rasen" style={{width: '100%', borderRadius: 5}}/>
        </Box>
        <Paper elevation={1} sx={{mt: 2, p: 4}}>
          <Typography variant="h5" fontWeight="bold" sx={{mb: 2}}>
            WIE kann ich helfen?
          </Typography>
          <Typography align="justify" sx={{mb: 2}}>
            Jede SPENDE zählt!
          </Typography>
          <Typography align="justify" sx={{mb: 2}}>
            Ganz einfach auf das Bankkonto der Fußballabteilung die Spendensumme überweisen:
          </Typography>
          <Box textAlign='center'>
            <Typography align="center" fontWeight="bold">
              Verwendungszweck: Flutlicht SVO
            </Typography>
            <Typography align="center" sx={{mb: 1}} fontWeight="bold">
              IBAN: DE47 7116 0000 0302 0677 81
            </Typography>
            <Button endIcon={<CopyAll/>} variant="outlined" sx={{mb: 2}} onClick={() => {
              copy('DE47711600000302067781')
              setSnackbarOpen(true)
            }}>IBAN kopieren</Button>
          </Box>

          <Typography align="justify">
            Über diesen Weg versuchen wir, mindestens 10.000 € zu sammeln, um das Projekt starten
            zu können.
          </Typography>
        </Paper>
        <Paper elevation={1} sx={{mt: 2, p: 4, mb: 2}}>
          <Typography variant="h5" fontWeight="bold" sx={{mb: 2}}>
            Was bekomme ich dafür?
          </Typography>
          <Typography align="justify" sx={{mb: 2}}>
            Jeder Spender erhält eine Spendenquittung für seine Steuererklärung, mindert damit sein Einkommen und spart
            sich zugleich die Steuerschuld in Höhe seines persönlichen Steuersatzes.
          </Typography>
          <Typography align="justify" sx={{mb: 2}}>
            Sollte das Projekt umgesetzt werden können, würden wir eine Sponsorentafel am Sportplatz mit allen Sponsoren
            errichten.
          </Typography>

          <Typography align="justify">
            Aber der größte Mehrwert sind tatsächlich zufriedene und glückliche Kinder, Jugendliche, Frauen und Herren,
            welche allesamt im Verein aktiv Fußball spielen!
          </Typography>
        </Paper>
      </Container>
      <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          severity="success"
          variant="filled"
          sx={{width: '100%'}}
        >
          Erfolgreich kopiert!
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default App;
